.top-menu {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 7vw;
    justify-content: center;
    background-color: var(--background-color);
    z-index: 100;
}

.logo {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    color: var(--light-neon-blue);
}

.logo h1 {
    font-size: 50px;
    font-weight: 600;
    margin: 0;
}

.menu {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.menu ul {
    list-style: none;
    align-items: end;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.menu ul li {
    font-size: 20px;
}

.menu ul li a {
    text-decoration: none;
    color: var(--light-neon-blue);
}

.menu ul li a:hover {
    text-decoration: underline;
}


@media (max-width: 815px) {
    .top-menu {
        flex-direction: column;
        height: 20vh;
        align-items: center;
        justify-content: center;
        width: 90%;
        position: relative;
    }

    .logo {
        width: 90%;
        justify-content: center;
        align-items: center;
    }

    .menu {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
    }
}