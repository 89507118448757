.skill-fragment {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22%;
    height: max-content;
    margin: 1%;
    margin-top: 3%;
}

.skill-fragment h1 {
    font-size: 22px;
    font-weight: 400;
    color: var(--light-neon-blue);
    margin: 0;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--light-neon-blue);
}

.skill-fragment ul {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
}

.skill-fragment ul li {
    font-size: 17px;
    font-weight: 400;
    padding-top: 2%;
    text-align: start;
    padding-bottom: 2%;
    width: 100%;
}

@media (max-width: 1000px) {
    .skill-fragment {
        width: 48%;
    }
}