footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 10vh;
    color: white;
    font-size: 20px;
}

footer p {
    text-align: center;
    width: 90%;
    margin: 0;
}

@media (max-width: 1000px) {
    footer {
        margin-top: 10%;
    }
}