.projects {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 5%;
    justify-content: center;
    align-items: center;
}

.projects h1.title {
    text-align: start;
    width: 90%;
    margin: 2%;
    margin-left: 0;
    margin-bottom: 1%;
    font-size: 30px;
}

.projects ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: start;
    justify-content: center;
}

@media (max-width: 1000px) {
    .projects {
        width: 90%;
    }

    .projects ul {
        width: 100%;
        align-items: center;
    }
}