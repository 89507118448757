.description {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-top: 22vh;
    justify-content: center;
}

.description-data {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.description-data p {
    margin: 0;
    font-size: 25px;
}

.description-data p:nth-child(2) {
    font-weight: 600;
    font-size: 50px;
    color: var(--light-neon-blue);
}

.description-image {
    width: 30%;
    display: flex;
    align-items: end;
    justify-content: end;
    padding-left: 10%;
}

.description-image .image {
    width: 300px;
    height: 300px;
    overflow: hidden;
}

.description-image .image img {
    position: absolute;
    width: 250px;
    height: 250px;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
}

.description-image .image img:hover {
    opacity: 0;
}

.description-image .image .second-image {
    opacity: 0;
}

.description-image .image .second-image:hover {
    opacity: 1;
}

@media (max-width: 1000px) {
    .description {
        flex-direction: column;
        margin-top: 3vh;
        align-items: center;
    }

    .description-data {
        width: 90%;
        padding-left: 0;
    }

    .description-data p:nth-child(3) {
        align-self: end;
    }

    .description-image {
        justify-content: center;
        align-items: center;
        width: 90%;
        padding: 0;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .description-image .image {
        width: 250px;
        height: 250px;
    }
}