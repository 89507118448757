.skills {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 5%;
    justify-content: center;
    align-items: center;
}

.skills .hard-skills,
.skills .soft-skills {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.hard-skills h1.title,
.soft-skills h1.title {
    text-align: start;
    width: 100%;
    margin: 2%;
    margin-left: 0;
    font-size: 30px;

}

.hard-skills ul,
.soft-skills ul {
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
}

@media (max-width: 1000px) {
    .skills {
        margin-top: 10%;
    }
}