.contact {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 5%;
    margin-bottom: 1%;
    justify-content: center;
    align-items: center;
}

.contact h1.title {
    text-align: start;
    width: 90%;
    margin: 2%;
    margin-left: 0;
    margin-bottom: 1%;
    font-size: 30px;
}

.contact p.description {
    display: flex;
    justify-content: start;
    width: 90%;
    margin: 2%;
    margin-left: 0;
    font-size: 17px;
    margin-top: 0;
}

.contact ul {
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    list-style: none;
    margin: 0;
    padding: 0;
}

@media (max-width: 1000px) {
    .contact {
        margin-top: 10%;
    }
}