.about-me {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.about-me p {
    width: 90%;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: white;
    text-indent: 4%;
    text-align: justify;
}